<template>
    <div style="display: flex; align-items: flex-end">
        <el-cascader :placeholder="addressIds||'请选择地区'" :props="props" @change="handleChange"
                     placeholder-style="color:black!important"></el-cascader>
    </div>
</template>
<script>

  export default {
    name: 'Cascader',
    props: {
      addressIds: {
        type: String,
        default() {
          return {}
        }
      }
    },
    data() {
      let t = this
      return {
        placeholder:'',
        props: {
          lazy: true,
          value: 'id',
          label: 'name',
          lazyLoad(node, resolve) {
            const { level } = node
            if (level == 0) {
              t.$api.getRegion().then((res) => {
                if (res) {
                  const nodes = res
                  nodes.forEach(item => {
                    item.leaf = level >= 1 //判断是否为末尾节点，这个地方是0,1,2三级
                  })
                  resolve(nodes)
                }
              })
            } else if (level > 0) {
              t.$api.region_children({ pid: node.value }).then((res) => {
                if (res) {
                  const nodes = res
                  nodes.forEach(item => {
                    item.leaf = level >= 2 //判断是否为末尾节点，这个地方是0,1,2三级
                    if (item.id == t.addressIds.city) {
                      t.placeholder = item.name
                    }
                  })
                  resolve(nodes)
                }
              })
            }

          }
        }
      }
    },
    methods: {
      async infoData() {
        let data = await this.$api.getRegion()
        this.$nextTick(() => {
          this.infoData = data
        })
      },
      handleChange(val) {
        this.$emit('getaddressinfo', val)
      }
    },

  }

</script>
