<template>
  <div>
    <el-card class="box-card">
      <div class="clearfix" slot="header">
        <span class="title">基本信息</span>
      </div>
      <div>
        <el-form :label-position="labelPosition" :model="tableData" :rules=rules
                 class="newForm" label-width="80px"
                 ref="tableData">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-card shadow="never" style="min-height: 500px">
                <el-row :gutter="24">
                  <el-col :span="6">
                    <ApeUploader :limit="1" :upload-file-list="uploadFileList"
                                 @handleUploadRemove="handleUploadRemove"
                                 @handleUploadSuccess="handleUploadSuccess"
                                 uploadTip="请上传头像，最多上传1份。"></ApeUploader>
                  </el-col>
                  <el-col :offset="2" :span="16">
                    <el-form-item label="红人昵称" prop="nickname">
                      <el-input placeholder="红人昵称" v-model="tableData.nickname"></el-input>
                    </el-form-item>
                    <el-form-item label="性别">
                      <el-radio-group size="medium" v-model="tableData.sex">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                        <el-radio :label="0">其他</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="肤质">
                      <el-select placeholder="请选择红人肤质" v-model="tableData.skin_type">
                        <el-option label="干性皮肤" value="干性皮肤"></el-option>
                        <el-option label="中性皮肤" value="中性皮肤"></el-option>
                        <el-option label="敏感性皮肤" value="敏感性皮肤"></el-option>
                        <el-option label="混合性皮肤" value="混合性皮肤"></el-option>
                        <el-option label="其他" value="其他"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="账号标签">
                      <el-tag :disable-transitions="false"
                              :key="tag+index"
                              @close="handleClose(tag)"
                              closable
                              size="medium"
                              v-for="(tag,index) in tableData.account_label">
                        {{ tag }}
                      </el-tag>
                      <el-input
                          @blur="handleInputConfirm"
                          @keyup.enter.native="handleInputConfirm"
                          class="input-new-tag"
                          ref="saveTagInput"
                          size="small"
                          v-if="inputVisible"
                          v-model="inputValue"
                      >
                      </el-input>
                      <el-button @click="showInput" class="button-new-tag" size="small" v-else>+
                        新标签
                      </el-button>
                      <!--                                            <el-input placeholder="账号标签" v-model="tableData.tag"></el-input>-->
                    </el-form-item>
                    <el-form-item required prop="city">
                      <Cascader :addressIds="tableData.area" :addresstype="3"
                                @getaddressinfo="getaddressinfo"></Cascader>
                      <template slot="label">
                        <i class="el-icon-place"></i> 地区
                      </template>
                    </el-form-item>
                    <el-form-item label="详细地址" prop="address" required>
                      <el-input placeholder="详细地址" v-model="tableData.address"></el-input>
                    </el-form-item>
                    <el-form-item label="擅长" prop="skill_id" required>
                      <el-select v-model="tableData.skill_id">
                        <el-option :key="index+'category'"
                                   :label="item.display_name"
                                   :value="item.id"
                                   v-for="(item,index) in category">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="简介">
                      <el-input placeholder="简介" type="textarea"
                                v-model="tableData.intro"></el-input>
                    </el-form-item>
                    <el-form-item label="开通平台" prop="opened_platforms">
                      <!--                      <el-select multiple placeholder="请选择开通平台"-->
                      <!--                                 v-model="tableData.opened_platforms">-->
                      <!--                        <el-option :key="index" :label="item.name"-->
                      <!--                                   :value="item.code"-->
                      <!--                                   v-for="(item,index) in platforms"></el-option>-->
                      <!--                      </el-select>-->
                      <span>{{ this.projectType === 'tiktok' ? '抖音' : '小红书' }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card :offset="1" shadow="never" style="height: 500px">
                <el-form-item label="所属机构" prop="organ_name">
                  <el-input placeholder="所属机构" v-model="tableData.organ_name"></el-input>
                </el-form-item>
                <el-form-item label="配合度">
                  <el-select placeholder="配合度" v-model="tableData.fit">
                    <el-option label="红榜" value="红榜"></el-option>
                    <el-option label="黑榜" value="黑榜"></el-option>
                    <el-option label="一般" value="一般"></el-option>
                    <el-option label="暂无" value="暂无"></el-option>

                  </el-select>
                </el-form-item>
                <el-form-item label="资料来源" prop="source" required>
                  <el-select placeholder="请选择资料来源" v-model="tableData.source">
                    <el-option label="公司交接" value="公司交接"></el-option>
                    <el-option label="媒介挖掘" value="媒介挖掘"></el-option>
                    <el-option label="签约部推荐" value="签约部推荐"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="媒介" prop="referrer" required>
                  <el-input @focus="dialogData.dialogVisible=true" inputValue='选择媒介'
                            required v-model="tableData.referrer"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="contact_mode">
                  <el-select placeholder="选择" style="float:left;width:120px"
                             v-model="tableData.contact_mode.mode">
                    <el-option label="微信" value="wechat"></el-option>
                    <el-option label="手机号" value="mobile"></el-option>
                  </el-select>
                  <el-input placeholder="联系方式" style="float:left;width: calc(100% - 120px)"
                            v-model="tableData.contact_mode.value"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input :autosize="{ minRows: 5}" type="textarea"
                            v-model="tableData.remark"
                  ></el-input>
                </el-form-item>
              </el-card>
            </el-col>

            <el-col :span="8">
              <el-card :offset="1" shadow="never" style="height: 500px">
                <div class="clearfix" slot="header">
                  <span>粉丝画像</span></div>
                <el-col :span="12">
                  <el-form-item label="性别">
                    <el-radio-group size="medium" v-model="tableData.fans_info[0].sex">
                      <el-radio :label="1">男</el-radio>
                      <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="比例%">
                    <el-input placeholder="比例%" v-model="tableData.fans_info[0].rate"></el-input>
                  </el-form-item>
                  <el-form-item label="人群标签TOP">
                    <el-checkbox-group v-model="tableData.fans_info[0].label">
                      <el-checkbox label="小镇青年"></el-checkbox>
                      <el-checkbox label="都市蓝领"></el-checkbox>
                      <el-checkbox label="Z时代"></el-checkbox>
                      <el-checkbox label="学生党"></el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <el-form-item label="主要年龄">
                    <el-checkbox-group v-model="tableData.fans_info[0].master_age">
                      <el-checkbox label="<18"></el-checkbox>
                      <el-checkbox label="18~35"></el-checkbox>
                      <el-checkbox label=">35"></el-checkbox>
                      <el-checkbox label="学生党"></el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>
              </el-card>
            </el-col>
          </el-row>
          <br>
          <br>
          <el-row :gutter="20">
            <el-col :offset="12" :span="14">
              <el-button @click="onSubmit('tableData')" size="medium" type="primary">{{ btnText }}
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-dialog :before-close="handleClose"
               :visible.sync="dialogData.dialogVisible" title="选择媒介" width="800">
      <el-form :inline="true" ref="rolesUserForm" style="padding: 20px;">
        <div class="notify-dept">
          <DingTalkDeptSelect @check="getDeptMembers" ref="refDngTalkDept" style="float: left;"/>
          <div class="all_box" style="overflow: scroll;max-height: 500px">
            <div class="member-list">
              <el-radio-group @change="handleCheckedCitiesChange" v-model="checkedMembers">
                <el-radio :key="member.userid+'222'" :label="member" border size="large"
                          v-for="member in members">
                  <el-tooltip :content="member.position" effect="light" placement="bottom">
                    <span>{{ member.name }} <span>{{ $utils.encryptMobile(member.mobile) }}</span></span>
                  </el-tooltip>
                </el-radio>
                >
              </el-radio-group>
            </div>
          </div>
        </div>
        <br>
        <el-button :disabled="dialogData.loadingNotify" :loading="dialogData.loadingNotify"
                   @click="choiceSource"
                   icon="el-icon-chat-line-square" type="primary">确定选择
        </el-button>
        <el-button :disabled="dialogData.loadingNotify" :loading="dialogData.loadingNotify"
                   @click="dialogData.dialogVisible=false"
                   icon="el-icon-chat-line-square">取消
        </el-button>

      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { editTable } from '@/components/mixins/editable'
import ApeUploader from '@/components/ApeUploader'
import DingTalkDeptSelect from '@/pages/departments/components/DingTalkDeptSelect'
import Cascader from '@/pages/kol/components/Cascader'

export default {
  name: 'ArtistIntro',
  mixins: [editTable],
  components: { ApeUploader, DingTalkDeptSelect, Cascader },
  data() {
    return {
      //项目id
      projectId: '',
      dialogData: {
        isIndeterminate: true,
        checkAll: false,
        loadingNotify: false,
        showTaskList: false,
        dialogVisible: false
      },
      labelPosition: 'left',
      dataList: [],
      searchCondition: [],
      showEditIcon: true,//是否显示编辑按钮
      loadingAdd: false,
      inputVisible: false,
      inputValue: '',
      platform: [],
      tableData: {
        nickname: null, //红人昵称
        organ_name: '', //机构名称
        referrer: '', //推荐人
        source: '', //来源
        sex: 0, //性别 1:男 2:女 0:保密
        contact_mode: {
          mode: '', //mobile:手机 wechat:微信
          value: '' //手机号/微信
        }, //联系方式

        skill_id: '', //擅长图文
        avatar: '', //头像
        address: '', //地址
        platforms: [], //平台
        fans_info: [{
          sex: '', //性别
          rate: '', //比例
          label: [], //标签
          master_age: [] //年龄范围
        }],//粉丝画像
        account_label: [], //账号标签
        skin_type: '', //肤质
        city: '', //城市
        intro: '', //简介
        fit: '', //红榜
        remark: '', //备注
        area: '请输入地址'
        , opened_platforms: []

      },
      rules: {
        city: { required: true, message: '请选择地址', trigger: 'blur' },
        address: { required: true, message: '请输入详细地址', trigger: 'blur' },
        nickname: { required: true, message: '请输入红人昵称', trigger: 'blur' },
        organ_name: { required: true, message: '请输入所属机构（或个人）', trigger: 'blur' },
        source: { required: true, message: '请输入资料来源', trigger: 'blur' },
        referrer: {
          required: true, message: '请选择媒介人', trigger: 'blur'
        },
        skill_id: {
          required: true, message: '请选择擅长选项', trigger: 'blur'
        }
      },
      uploadFileList: [],
      urlList: [
        {}
      ],
      checkedMembers: [],
      members: [],
      btnText: '新增',
      addressIds: {},
      category: [],
      platforms: [] //平台
    }
  },
  methods: {

    async infoData() {
      let { list } = await this.$api.getCategoryListByIdentify(`skill-category`)
      this.category = list
      if (this.projectId !== 'add') {
        this.btnText = '提交修改'
        let { info } = await this.$api.getKoldetails({ id: this.projectId })
        this.$nextTick(() => {
          if (info?.fans_info) {
            info.fans_info[0].master_age = info?.fans_info[0]?.master_age ? info?.fans_info[0]?.master_age : []
          } else {
            info['fans_info'] = [{
                  sex: '', //性别
                  rate: '', //比例
                  label: [], //标签
                  master_age: [] //年龄范围
                }]
          }
          this.tableData = info,
              this.addressIds = {
                province: info.province,
                city: info.city,
                district: info.district
              }

          this.addressIds.toString()
          this.uploadFileList = [{ url: info.avatar }]
        })
      }
    },
    async formSubmit() {
      if (this.tableData.account_label?.length === 0) {
        delete this.tableData.account_label
      }
      this.tableData.opened_platforms = []

      this.tableData.opened_platforms.push(this.projectType === 'tiktok' ? 'douyin' : 'xiaohongshu')
      if (this.btnText === '新增') {

        this.$api.saveKol(this.tableData).then((id) => {
          if (id) {
            this.$notify.success(this.btnText + '成功')
            this.$router.push({
              name: 'kol_ArtistIntro',
              params: { id: id }
            })
          }
        })
      } else {
        this.tableData.id = this.projectId
        let delData = ['co_brand', 'last_co_time', 'rs_rate', 'update_by', 'co_num', 'uid', 'create_by', 'created_at', 'r_num', 'updated_at']
        delData.forEach((item) => {
          if (String(item) in this.tableData) {
            delete this.tableData[item]
          }
        })
        this.$api.saveKol(this.tableData).then((id) => {
          if (id) {
            this.$notify.success(this.btnText + '成功')
            this.$router.push({
              name: 'kol_ArtistIntro',
              params: { id: this.tableData.id }
            })
          }
        })
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
            if (valid) {
              this.$confirm(`是否确认${this.btnText}?`, '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.formSubmit()
              }).catch(() => {
                this.$notify.info('已取消')
              })
            } else {
              return false
            }
          }
      )
    },

    // 图片删除回调
    handleUploadRemove() {
      this.tableData.avatar = 'http://tisu-pd.oss-cn-hangzhou.aliyuncs.com/platform_beta/20220719/T20220719142555_32702.png'
    },
    handleUploadSuccess(file) {
      this.tableData.avatar = file.full_path
    },

    handleClose(tag) {
      this.tableData.account_label.splice(this.tableData.account_label.indexOf(tag), 1)
      this.dialogData.dialogVisible = false
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.tableData.account_label.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    //
    // 媒介选择模块---
    getDeptMembers(data, checkedObj) {
      let depts = checkedObj.checkedNodes
      this.defaultCheckBox()
      depts.forEach((item) => {
        this.loadMembers({ dpt_id: item.dpt_id })
      })
    },
    handleCheckAllChange(val) {
      this.checkedMembers = val ? [...this.members] : []
      this.dialogData.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.dialogData.checkAll = checkedCount === this.members.length
      this.dialogData.isIndeterminate = checkedCount > 0 && checkedCount < this.members.length
    },
    async loadMembers(searchCondition) {
      searchCondition.page_size = 50
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      list.forEach((item) => {
        let index = this.members.findIndex((value) => value.userid === item.user_id)
        if (index === -1) this.members.push(item)
      })
      searchCondition.page_size = pages.page_size
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        this.members = [...this.members, ...list]
      }
    },
    defaultCheckBox() {
      this.dialogData.checkAll = false
      this.checkedMembers = []
      this.members = []
      this.dialogData.isIndeterminate = true
    },
    //选择媒介
    choiceSource() {
      let t = this
      if (this.checkedMembers) {
        t.tableData.referrer = t.checkedMembers.name
        t.dialogData.dialogVisible = false
      } else {
        this.$notify.error({
          message: '请选择媒介'
        })
      }
    },
    //媒介---end
    //获取地址
    getaddressinfo(val) {
      this.tableData.province = val[0]
      this.tableData.city = val[1]
      this.tableData.district = val[2]
    }
  },
  created() {
    this.projectId = this.$route.params.id
    this.projectType = this.$route.params.type
    this.infoData()
  }
}
</script>
<style lang="scss">
.title {
  font-size: 18px;
  font-weight: 800;
}

.newForm {
  /*border: 1px solid red;*/

  .el-form-item__label {
    color: black;
    font-weight: 600;
  }

  .el-form-item--mini.el-form-item {
    margin-bottom: 13px;
  }
}

.platforms-tab .el-tabs__item {
  display: block;
  margin: 1px 1px;
  line-height: 25px;
  font-size: 1rem;
  /*word-wrap: break-word;*/
  writing-mode: horizontal-tb;
  height: 80px;
  padding-left: 10px;
  padding-top: 10px;
  overflow-x: hidden;
}

.el-tag + .el-tag {
  margin-left: 10px;
  margin-bottom: 10px;

}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.dept-member {
  width: 90%;
  border: #dcdfe6 1px solid;
  min-height: 500px;
  margin: 0 auto;
}

.notify-dept {
  width: 500px;
  border: #dcdfe6 1px solid;
  padding: 10px;
  /*max-height: 300px;*/
  overflow-y: scroll;
}

.all_box {
  margin-left: 90px;
  /*margin-top: 20px;*/
  float: left;
}

.check-all {
  height: 20px;
  padding: 5px;
  margin-bottom: 5px;
}

.member-list {
  > div > label {
    display: block;
    width: 180px;
    margin: 10px;
    padding: 0;
    line-height: 22px;
  }
}

</style>
